import { Card } from "./card";

export const cards = [
  new Card("asunder", "same", 1),
  new Card("dawn", "higher-than", 3),
  new Card("fortune", "plus-1", 3),
  new Card("iron", "plus-1", 4),
  new Card("leviathan", "discard-lower", 4),
  new Card("midnight", "higher-than", 3),
  new Card("the-ash", "help"),
  new Card("the-beast", "twice", 1),
  new Card("the-belltower", "discard-lower", 2),
  new Card("the-blind-man", "1-4-7", 1),
  new Card("the-captain", "1-4-7", 3),
  new Card("the-chalice", "1-4-7", 2),
  new Card("the-deep", "higher-than", 3),
  new Card("the-engine", "5-6-7", 3),
  new Card("the-fall", "amplify"),
  new Card("the-feast", "relocate", 4),
  new Card("the-ghost", "plus-1", 2),
  new Card("the-huntress", "twice", 1),
  new Card("the-imago", "cycle", 3),
  new Card("the-judge", "3-4-5", 3),
  new Card("the-key", "old", 3),
  new Card("the-lantern", "relocate", 3),
  new Card("the-lord", "5-6-7", 3),
  new Card("the-mirror", "twice", 1),
  new Card("the-musicians", "exile", 3),
  new Card("the-noble", "3-4-5", 3),
  new Card("the-north-wind", "slow", 2),
  new Card("the-pallbearers", "within-2", 2),
  new Card("the-passage", "same", 2),
  new Card("the-prophet", "cycle", 2),
  new Card("the-rider", "cycle", 2),
  new Card("the-servant", "1-2-3", 2),
  new Card("the-shore", "discard-lower", 4),
  new Card("the-stranger", "1-2-3", 3),
  new Card("the-thief", "help", 2),
  new Card("the-wish", "old", 2),
];
